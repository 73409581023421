<template lang="html">
  <div class="">

    <div class="main">
      <div class="subdiv">
        <div :style="{display:'flex'}">
          <div class="">
            <h1 :style="{fontSize:'26px',fontWeight:'bold',textAlign:'left'}">
                 {{this.name}}
            </h1>
            <h2 class="h2_dator" >
              {{ this.short_description }}<br> Besök artikeln  <a :href='this.website'   >
                <span :style="{height:'25px',width:'25px', textDecoration:'underline'}" >
                  här
                </span>
               </a>
            </h2>
          </div>
          <!-- <img :src="`${publicPath}`+ this.logo" alt="" class="logo_image"> -->
        </div>

        <div class="article_webb" >
          <div class="">
            <div :style="{width:'80%'}" v-for="(article_part,idx) in article_text" :key="idx">
              <div :style="{textAlign:'left'}">
                {{date}}
              </div>
              <h2 :style="{fontSize:'17px',fontWeight:'bold',marginTop:'2%',textAlign:'left'}">
                {{article_part.headline}}
              </h2>
              <h3 :style="{fontSize:'15px',textAlign:'left',marginTop:'0.2%'}">
                {{article_part.text}}
              </h3>
            </div>
          </div>
          <div :style="{marginTop:'0%', textAlign:'center'}">
            <img :src="`${publicPath}`+ this.image" alt="" :style="{height:'295px', marginTop:'1%',width:'290px'}">
          </div>
        </div>
        <div class="article_mob" >
          <div class="">
            <h2 class="h2_mob" >
              {{ this.short_description }}<br> Besök artikeln  <a :href='this.website'   >
                <span :style="{height:'25px',width:'25px', textDecoration:'underline'}" >
                  här
                </span>
               </a>
            </h2>

            <div :style="{width:'80%'}" v-for="(article_part,idx) in article_text" :key="idx">
              <div :style="{textAlign:'left'}">
                {{date}}
              </div>
              <h2 :style="{fontSize:'17px',fontWeight:'bold',marginTop:'2%',textAlign:'left'}">
                {{article_part.headline}}
              </h2>
              <h3 :style="{fontSize:'15px',textAlign:'left',marginTop:'0.2%'}">
                {{article_part.text}}
              </h3>
            </div>
          </div>

          <div :style="{textAlign:'left'}">
            <img :src="`${publicPath}`+ this.image" alt="" :style="{height:'195px', marginTop:'4%',width:'210px'}">
          </div>

        </div>

      </div>
    </div>


  <bottombar/>

  </div>
</template>

<script>

import bottombar from '../components/bottombar.vue';


export default {
  data: function () {
    return {
      name:"",
      date:"",
      short_description:"",
      article_list:[],
      article_text:[],
      website:"",
      publicPath: process.env.BASE_URL +'',
      image:"",
      logo:"",
      id:0
    }
  },
  components:{
    bottombar
  },
  mounted(){
    this.id=this.$route.query.id

    window.scrollTo(0,0)

    this.article_list = [
      {
        "id": 0,
        "short_description": "Överenskommelse om att stärka konsumenternas rätt till reparation",
        "image": "rightorepair.jpg",
        // "logo":"spagan.png",
        "website":"https://www.europarl.europa.eu/news/en/press-room/20240129IPR17216/deal-on-strengthening-consumers-right-to-repair",
        "date":" 2024-02-26",
        "article_text":[
          {
            "headline":"EU stärker rätt till reparation",
            "text": "EU:s förhandlare nådde en preliminär överenskommelse om ett lagförslag som syftar till att minska avfallet och stärka reparationssektorn genom att göra produktreparationer mer tillgängliga och kostnadseffektiva. "
          },
        ]
      },
      {
        "id": 1,
        "short_description": "Frankrike röstar för att begränsa överförsäljning av snabbmode med miljöavgifter ",
        "image": "waste.jpg",
        // "logo":"spagan.png",
        "website":"https://www.theguardian.com/world/2024/mar/15/france-fast-fashion-law-environmental-surcharge-lower-house-votes",
        "date":" 2024-03-19",
        "article_text":[
          {
            "headline":"Frankrike vill begränsa överförsäljning av snabbmode",
            "text": "Frankrike har ställt sig bakom en rad åtgärder för att göra billigt snabbmode, mindre attraktiva för köpare. Till de viktigaste åtgärderna hör ett förbud mot reklam för de billigaste textilierna och en miljöavgift på lågprisvaror "
          },
        ]
      }

    ]
    this.name = this.article_list[this.id]["name"]
    this.short_description = this.article_list[this.id]["short_description"]
    this.website = this.article_list[this.id]["website"]
    this.image = this.article_list[this.id]["image"]
    this.logo = this.article_list[this.id]["logo"]
    this.article_text= this.article_list[this.id]["article_text"]
    this.date = this.article_list[this.id]["date"]
  }


}
</script>

<style lang="css" scoped>
.main{
  width:100%;
  margin-top: 3%;
  padding-bottom: 80px;
  background-color: white;
}

@media (max-width:450px ){
  .article_webb{
    display: none
  }
  .subdiv{
    width:94%;
    margin-top: 2%;
    margin-left: 6%;
    padding-bottom: 80px
  }

  .h2_mob{
    margin-top: 3%;
    width: 90%;
    font-size: 19px;
    text-align: left;
    padding-bottom: 10px;
  }

  .h2_dator{
    display: none
  }

  .logo_image{
    height:40px;
    margin-left:14%;
    margin-top: 1%;
    width:50px
  }


}

@media (min-width:450px ){
  .subdiv{
    width:80%;
    margin-top: 2%;
    margin-left: 6%;
    padding-bottom: 80px
  }

  .article_webb{
    margin-top: 4%;
    display: flex;
  }
  .article_mob{
      display: none
  }

  .h2_dator{
    font-size: 19px;
    text-align: left;
  }

  .h2_mob{
    display: none
  }

  .logo_image{
    height:80px;
    margin-left:29%;
    margin-top: 1%;
    width:120px
  }


}

</style>
