<template lang="html">
  <div class="">
    <div :style="{height:'30px', marginTop:'2%',textAlign:'left' }" >
      <button type="button"
        :style="{borderStyle:'none',height:'30px',marginLeft:'7%',textAlign:'left',
        fontSize:'17px',borderRadius:'8px',
        borderStyle:'solid',borderColor:'grey',borderWidth:'0.8px' ,backgroundColor:'#0A6338',
          width:'180px',color:'white'}"
         @click="open_lan()" name="button">
         Välj annat län
         <b-icon  icon="caret-down"  class="caret" ></b-icon>
       </button>
       <div :style="{borderRadius:'8px',height:'30px',width:'auto' ,marginLeft:'7%',  color:'black'}"   >
          Län: {{this.lan}}
       </div>
      <div v-if= "this.lan_opened" :style="{height:'auto',paddingBottom:'15px',border:0, position:'absolute',
            borderWidth:'0', boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',textAlign:'left',
             borderRadius:'0px 3px 3px 0px',marginTop:'0%',overflowY:'auto',borderRadius:'3px',
            borderColor:'black',  opacity:'100%',zIndex:13, width:'55%',marginTop:'0%',
            backgroundColor:'white',marginLeft:'7%'}">
        <div  v-for="(lan,idx) in län_list" :style="{marginLeft:'4%'}"
              :key="idx" @click="choose_lan(lan.lan, lan.id)" >
          <div :style="{height:'20px',fontSize:'15px', display:'flex'}" >
            <div :style="{marginLeft:'1%',marginTop:'0.5%', color:'black'}">
              {{lan.lan}}
            </div>
          </div>
         </div>
      </div>
     </div>



    <div  class="search_header"      >
      Sökresultat: {{this.category_name}} <span v-if="this.lan_id !=0 ">i</span>  {{this.lan}}
    </div>


    <div :style="{height:'35px',textAlign:'left' }" v-if="this.lan_id !=0">
      <button type="button"
        :style="{borderStyle:'none',height:'25px',marginLeft:'7%', fontSize:'15px',borderRadius:'8px',
         boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.25)',paddingRight:'9%',
         backgroundColor:'white', borderColor:'grey', borderStyle:'solid' ,width:'auto',color:'black'}"  v-if="!city_chosen" @click="open_city()" name="button">
         Vilken kommun bor du i?
         <b-icon  icon="caret-down" class="caret"></b-icon>
       </button>
       <div :style="{borderRadius:'8px',height:'30px',width:'230px' ,marginLeft:'7%', backgroundColor:'white',borderWidth:'1px', borderStyle:'solid' ,color:'black'}"
            v-if="city_chosen">
           {{this.city_name}}
           <b-icon  icon="x-lg" @click="remove_city_filter()" :style="{position:'absolute',marginLeft:'1%', marginTop:'0.5%'}"></b-icon>
       </div>
        <!-- <button type="button"
           :style="{borderStyle:'none', marginLeft:'7%',fontColor:'#8A8A8A',borderRadius:'3px 0px 0px 3px',
            boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.25)',   backgroundColor:'#0A6338',color:'white', width:'180px' }"
            v-if="city_chosen" name="button"
            @click="open_city()">{{this.city_name}}
          <b-icon  icon="caret-down" :style="{position:'absolute', marginTop:'0%'}"></b-icon>
        </button> -->
      <div v-if= "this.city_opened" :style="{height:'auto',paddingBottom:'15px',border:0, position:'absolute',
            borderWidth:'0', boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',textAlign:'left',     borderRadius:'0px 3px 3px 0px',marginTop:'0%',overflowY:'auto',borderRadius:'3px',
            borderColor:'black',  opacity:'100%',zIndex:13, width:'25%',marginTop:'0%',    backgroundColor:'white',marginLeft:'7%'}">
        <div  v-for="(city,idx) in city_filter_list" :style="{marginLeft:'4%'}"
              :key="idx" @click="choose_city(city.city)" >
          <div :style="{height:'20px',fontSize:'15px', display:'flex'}" >
            <div :style="{marginLeft:'1%',marginTop:'0.5%', color:'black'}">
              {{city.city}}
            </div>
          </div>
         </div>
      </div>
     </div>



    <div v-if=" result_list.length == 0" class="no_result">
      Tyvärr hittade vi inga resultat denna gången men skicka gärna en kontaktförfrågan så ska vi försöka hjälpa dig
    </div>


    <div class="result_list_flow" v-if="result_list.length != 0" >
      <div  @click="to_company(result.id)"
            v-for="(result,idx) in result_list" :key="idx"
            :style="{width:'86%', marginLeft:'7%',height:'180px',marginTop:'3%',background: '#FCFCFC',borderRadius: '18px',boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)'}">
        <div :style="{display:'flex'}">
          <img  :src= "get_image(result.id,result.image)" :style="{height:'111px',marginLeft:'3%' ,objectFit:'contain',width:'91px',marginTop:'2%'}" alt="">
          <div :style="{ fontSize:'15px', marginTop:'1%',height:'157px', width:'70%'}">
              <div :style="{display:'flex'}">
                <div :style="{width:'auto', textAlign:'left'}">
                      {{result.name }}
                </div>
                <b-icon v-if="result.verified==1"  icon="patch-check-fill"
                :style="{color:'blue', marginLeft:'5px',marginTop:'1%', height:'20px',width:'20px'}"/>
              </div>
              <div v-if="result.online_service" class="boxes">
                <div  class="label_lev">
                  Leverans över hela Sverige
                </div>
              </div>
              <div  v-if="!result.online_service" :style="{height:'25px',display:'flex' , overflowX:'auto' ,textAlign:'left', color:'black', fontStyle:'italic' }">
                <div :style="{paddingRight:'0.5%'}" v-for=" (c,id) in result.city" :key="id">
                  {{c[0]}},
                </div>
              </div>
            <div class="About">
              {{result.about}}
            </div>
          </div>
        </div>
      </div>
    </div>



    <div v-if="result_list.length != 0" class="result_list_flow_comp" >
      <div  @click="to_company(result.id)"
      v-for="(result,idx) in result_list"
      :key="idx"
      :style="{width:'86%', marginLeft:'7%',height:'200px',marginTop:'3%',background: '#FCFCFC',borderRadius: '18px',boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)'}">
        <div :style="{display:'flex'}">
          <img  :src= "get_image(result.id,result.image)" :style="{height:'111px',marginLeft:'3%' ,objectFit:'contain',width:'141px',marginTop:'2%'}" alt="">
          <div :style="{ width:'90%',fontSize:'30px', marginTop:'1%',height:'40px',marginLeft:'5%'}">
            <div :style="{display:'flex'}">
                  <div :style="{width:'auto'}">
                        {{result.name}}
                  </div>
                  <b-icon v-if="result.verified==1"  icon="patch-check-fill"
                  :style="{color:'blue', marginLeft:'5px',marginTop:'1%', height:'20px',width:'20px'}"/>
              </div>
              <div v-if="!result.online_service"  :style="{height:'27px', display:'flex',textAlign:'left',fontSize:'18px', color:'black', fontStyle:'italic' }">
                <div :style="{paddingRight:'0.5%'}" v-for=" (c,id) in result.city" :key="id">
                  {{c[0]}}
                </div>
              </div>
              <div v-if="result.online_service" class="boxes">
                <div  class="label_lev">
                  Leverans över hela Sverige
                </div>
              </div>
            <div class="About">
              {{result.about}}
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
 //import Navigation from '../components/Navigation.vue';
import router from '../router/index'
 import axios from 'axios';
 import {API_URL} from '../../config.js'
import {bus} from '../main'
export default {

  data:function(){
    return{
      publicPath: process.env.BASE_URL +'',
      result_list:[],
      city_chosen:false,
      city_name:'',
      from_page:'',
      lan :'',
      lan_chosen : true,
      lan_opened :false,
      category_name:'',
      w:0,
      lan_id:0,
      city_filter_on : false,
      city_filter_activated:false,
      lan_filter_start:false,
      category_id:0,
      city_opened:false,
      current_page_nr:0,
      total_page_nr:0,
      online_service:false,
      city_filter_list:[],
      lan_filter_activated:false

    }
  },
  methods:{
    choose_city(city){
      this.$store.commit('set_city_in_lan', city)

      this.city_name = city
      this.city_chosen = true
      this.city_opened=false
      this.city_filter_activated= true

      this.get_result_companies(this.category_id,this.lan_id,1)
    },

    choose_lan(lan,lan_id){
      this.lan_id =lan_id
      this.lan = lan
      this.lan_chosen = true
      this.lan_opened=false
      this.lan_filter_activated = true
      this.lan_filter_start =false
      this.result_list=[]
      this.remove_city_filter()
      //this.get_result_companies(this.category_id,this.lan_id,1)
    },

    remove_city_filter(){
      this.city_name = ''
      this.city_chosen = false
      this.$store.commit('set_city_in_lan', '')
      this.city_filter_activated= false
      this.get_result_companies(this.category_id,this.lan_id,1)
    },

    open_lan(){
      if(this.lan_opened){
        this.lan_opened=false
      }else {
        this.lan_opened=true
      }
    },

    open_city(){
        if(this.city_opened){
          this.city_opened=false
        }else {
          this.city_opened=true
        }
    },

    to_company(id){
            this.$forceUpdate()
            axios({
              method:'post',
              url: API_URL+ '/click_company',
              data:{'company_id':id}
            })
            .then(function(){
             })

      router.push({ path:"company_page", query:{id:id} })
    },
    addmore(){
          if ( this.current_page_nr  <this.total_page_nr ){
            var page_nr = Number(this.current_page_nr) +1
            this.get_result_companies(this.category_id,this.lan_id,page_nr)
          }
    },
    get_image(id,image){
      if (image){
        var data = 'enjordrepair_user' + id + "/" + image
        return this.$func.get_image(data)
      }
    },
    open_site(website){
      window.open(website, '_blank').focus();
    },

    get_result_companies(category_id,lan_id,page_nr){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_result_companies'+'?category_id='+category_id+'&lan_id='+lan_id+'&page_nr='+page_nr+'&city='+this.city_name,
        })
        .then(function(response){
//            self.result_list=response.data["result_list"]
            if( self.lan_filter_activated ||  self.city_filter_activated){
              self.result_list=[]
              self.result_list.push.apply(self.result_list,response.data["result_list"])
            }
            else{
              self.result_list.push.apply(self.result_list,response.data["result_list"])

            }
            self.current_page_nr = response.data["current_page_nr"]
            self.total_page_nr = response.data["total_page_nr"]


            self.get_lan_for_result_filter(lan_id)
            self.city_filter_activated = false
            self.lan_filter_activated = false
        })
    },
    get_lan_for_result_filter(lan_id){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_lan_for_result_filter'+'?lan_id='+lan_id +'&category_id='+this.category_id,
        })
        .then(function(response){
            self.city_filter_list = response.data

        })
    }
  },
  components:{
    //Navigation
  },
  watch: {
    "$route.params.id"() {
      //val
      // call the method which loads your initial state
        this.get_result_companies(this.category_id,this.lan_id,this.current_page_nr)
    },
  },
  created(){

    if(this.$store.state.lan_object ==undefined){
      this.lan =''
      this.lan_id =0
    this.lan_filter_start = true
    }
    else {
      this.lan_filter_start = true
       this.lan =this.$store.state.lan_object.lan
       this.lan_id = this.$store.state.lan_object.lan_id
       this.lan_filter_activated= true
    }

  },
  mounted(){
    this.category_name = this.$route.query.category_name

    this.category_id=this.$route.query.category_id
    // this.lan=this.$route.query.lan
    // this.lan_id=this.$route.query.lan_id
    if(this.lan_filter_start ){
      this.get_result_companies(this.category_id,this.lan_id,1)

    }
    this.w = window.screen.width



    if(this.$store.state.current_city !== undefined && this.$store.state.current_city.length !=0 ){
      this.choose_city(this.$store.state.current_city)
    }
    // else{
    //     console.log(this.$store.state.current_city);
    //   this.choose_city(this.$store.state.current_city)
    // }

    bus.$on('remove_filter', () => {
        this.remove_city_filter()
    })


    window.onscroll = () => {
       let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
       if (bottomOfWindow  ) {
         this.addmore()
       }
    }


    this.län_list =[
      {'id':1, 'lan': 'Blekinge län'   },
      {'id':2, 'lan': 'Dalarnas län'   },
      {'id':3, 'lan': 'Gotlands län'   },
      {'id':4, 'lan': 'Gävleborgs län'   },
      {'id':5, 'lan': 'Hallands län'   },
      {'id':6, 'lan': 'Jämtlands län'   },
      {'id':7, 'lan': 'Jönköpings län'   },
      {'id':8, 'lan': 'Kalmar län'   },
      {'id':9, 'lan': 'Kronobergs län'   },
      {'id':10, 'lan': 'Norrbottens län'   },
      {'id':11, 'lan': 'Skåne län'   },
      {'id':12, 'lan':  'Stockholms län'  },
      {'id':13, 'lan':  'Södermanlands län'  },
      {'id':14, 'lan':  'Uppsala län'  },
      {'id':15, 'lan':  'Värmlands län'  },
      {'id':16, 'lan':  'Västerbottens län'  },
      {'id':17, 'lan':  'Västernorrlands län'  },
      {'id':18, 'lan':  'Västmanlands län'  },
      {'id':19, 'lan':  'Västra Götalands län'  },
      {'id':20, 'lan':  'Örebro län'  },
      {'id':21, 'lan':  'Östergötlands län'  }
    ]




  }

}
</script>

<style lang="css" scoped>

.boxes{
   height:35px;
   padding-bottom: 0%;
   margin-left: 0%;
   text-align: left;
    /* display:flex; */
    /* overflow-x: auto; */
    width: 90%;
}

.label_lev{
  border-radius: 18px;
  background-color: blue ;
  height: 25px;
  color: white;
  width: 180px;
  padding-left: 1%;
  padding-right:  1%;
  padding-top: 0%;
  padding-bottom: 0%;
  margin-left: 1%;
  font-size: 13px;
  box-sizing: border-box;
  flex: none;
  /* display: inline-block;
  flex: none;
  box-sizing: border-box; */
}


@media (min-width:450px ){

  .About{
    font-size:16px;
    width:70%;
    display:flex;
    margin-top:0.5%;
    margin-left: 0%;
    height: 95px;
    overflow-y: hidden;
    text-align: left;
    color: black;
    justify-content: flex-start;
  }


  .result_list_flow_comp{
     padding-Bottom: 120px
  }

  .result_list_flow{
    display: none
  }

  .caret{
      position:absolute;
       margin-Top:0.5%;
  }

  .no_result{
    margin-Left:6.5%;
    width:70%;
  }

  .search_header{
    height:auto;
    margin-Left:7%;
    margin-Top:5%;
    text-Align:left ;
    font-Style: normal;
    font-Weight: 500;
    color: #000000;
    font-Size: 33px;
    line-Height: 45px;
    letter-Spacing: -0.3px
  }


}


@media (max-width:450px ){

  .About{
    max-width: 80%;
    width:80%;
      margin-top:4%;
    display:flex;
    margin-left: 0%;
    height: 90px;
    overflow-y: hidden;
    text-align: left;
    color: black;
    /* justify-content: flex-start; */
    line-height:15px
  }

  .caret{
      position:absolute;
       margin-Top:1%;
  }

  .search_header{
         height:auto;
         margin-Left:7%;
         margin-Top:9%;
         text-Align:left;
         font-Style: normal;
         font-Weight: 500;
         color: #000000;
         font-Size: 23px;
         line-Height: 24px;
         letter-Spacing: -0.3px;

  }

  .result_list_flow{
     padding-Bottom: 120px
  }

  .result_list_flow_comp{
    display: none
  }


  .no_result{
    margin-Left:6.5%;
    width:90%;
  }


}
</style>
